.quote-content {
    padding: 0 30px;
    text-align: center;

    &:before,
    &::after {
        display: inline-block;
        font-size: 80px;
        position: absolute;
        opacity: 0.3;
    }

    &:before {
        content: "\201C";
        top: 0;
        line-height: 1;
        left: 10px;
    }

    &:after {
        content: "\201D";
        bottom: 0;
        line-height: 0;
        right: 10px;
    }
}
