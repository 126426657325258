.container {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 40px;
}

h1.form-title {
    font-weight: 300;
    font-size: 40px;
}
